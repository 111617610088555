import React, { useEffect, useState } from "react";
import "./GetAllCertifiate.css";
import "../CertificateCard/CertificateCard.css";
import Footer from "../footer/Footer";
import CertificateCard from "../CertificateCard/CertificateCard";
import AdminDashboard from "../AdminPage/Admin";

const GetAllCertificate = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/certificate/getAllCartificate`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCertificates(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCertificates = certificates.filter((certificate) =>
    Object.values(certificate).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div className="certificates-list">
        <h1>All Certificates</h1>
        <input
          type="text"
          placeholder="Search certificates..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        {filteredCertificates.length > 0 ? (
          filteredCertificates.map((certificate) => (
            // <div
            //   key={certificate.certificateNumber}
            //   className="certificate-card"
            // >
            //   <img
            //     src={
            //       certificate.category_image ||
            //       "https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
            //     }
            //     alt="Certificate"
            //     className="certificate-image"
            //   />

            //   <p>
            //     <strong>Certificate Number:</strong>{" "}
            //     {certificate.certificateNumber}
            //   </p>
            //   <p>
            //     <strong>Student Name:</strong> {certificate.studentName}
            //   </p>
            //   <p>
            //     <strong>Father Name:</strong> {certificate.fatherName}
            //   </p>
            //   <p>
            //     <strong>Student Email:</strong> {certificate.studentEmail}
            //   </p>
            //   <p>
            //     <strong>Course:</strong> {certificate.course}
            //   </p>
            //   <p>
            //     <strong>Duration:</strong> {certificate.duration} years
            //   </p>
            // </div>
            <CertificateCard
              category_image={certificate.category_image}
              certificateNumber={certificate.certificateNumber}
              course={certificate.course}
              duration={certificate.deration}
              fatherName={certificate.fatherName}
              studentEmail={certificate.studentEmail}
              studentName={certificate.studentName}
            />
          ))
        ) : (
          <p>No certificates found.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default GetAllCertificate;
