import React from "react";
import "./AllUgCourses.css";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import CardGrid from "../CardGridpage/CardGrid";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AllUgCourses = () => {
  const location = useLocation();
  const imgpath = location.state;
  console.log(imgpath);

  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.username;

  const courseSelector = useSelector((res) => res.course.valuee);

  const filterData = courseSelector.filter(
    (e) => e.coursetype === imgpath.type
  );
  console.log(filterData);


  return (
    <>
      <div id="ugCourses-mainpage">
        <Nav name={name} />
        <div id="mainImagediv">
          <img src={imgpath.img} alt="" />
        </div>
        <div id="mainCards-section">
          {filterData.map((e, i) => {
            return (
              <CardGrid duration={e.courseduration} eligibility={e.courseeli} type={e.coursetype} img={e.courseimg} desc={e.coursedes} title={e.coursename} index={i} />
            );
          })}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllUgCourses;
