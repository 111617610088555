import React, { useState, useEffect } from "react";
import "./NotificationPage.css";

const NotificationPage = () => {
  const [jobData, setJobData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [isJobPaused, setIsJobPaused] = useState(false);
  const [isNotificationPaused, setIsNotificationPaused] = useState(false);

  // Fetch data from the API
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/notification/getNotification`
        );
        const data = await response.json();

        // Separate job and notification data
        const jobData = data.filter((item) => item.isType === "job");
        const notificationData = data.filter(
          (item) => item.isType === "notification"
        );

        setJobData(jobData); // Set job data in state
        setNotificationData(notificationData); // Set notification data in state
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    };

    fetchNotifications();
  }, []); // The empty dependency array ensures this runs only once on component mount

  return (
    <div className="news-wrapper">
      {/* Job Container */}
      <div className="news-container">
        <h3 className="notification-heading">Job Listings</h3>
        <div
          className={`news-content ${isJobPaused ? "paused" : ""}`}
          onMouseEnter={() => setIsJobPaused(true)}
          onMouseLeave={() => setIsJobPaused(false)}
        >
          <ul>
            {jobData.length > 0 ? (
              // Reverse the array to display the most recent job first
              jobData
                .slice()
                .reverse()
                .map((item, index) => (
                  <li key={index}>
                    <p className="news-item">{item.name}</p>
                  </li>
                ))
            ) : (
              <p>No jobs available.</p>
            )}
          </ul>
        </div>
      </div>

      <div className="news-container">
        <h3 className="notification-heading">Notifications</h3>
        <div
          className={`news-content ${isNotificationPaused ? "paused" : ""}`}
          onMouseEnter={() => setIsNotificationPaused(true)}
          onMouseLeave={() => setIsNotificationPaused(false)}
        >
          <ul>
            {notificationData.length > 0 ? (
              // Reverse the array to display the most recent notification first
              notificationData
                .slice()
                .reverse()
                .map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.examLink}
                      target="blank"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="news-item">{item.name}</p>
                    </a>
                  </li>
                ))
            ) : (
              <p>No notifications available.</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
