import React, { useEffect, useState } from "react";
import "./TypingPage.css";
import { useNavigate } from "react-router-dom";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import StudentQuiz from "../StudentQuiz/StudentQuiz";

const TypingPage = () => {
  const [randomText, setRandomText] = useState("Select day for the typing");
  const [inputText, setInputText] = useState("");
  const [correctKeysPressed, setCorrectKeysPressed] = useState(0);
  const [totalKeysPressed, setTotalKeysPressed] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedMinutes, setSelectedMinutes] = useState(1);
  const [wpm, setWpm] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [language, setLanguage] = useState("english");
  const [scrollIndex, setScrollIndex] = useState(0);
  const [showResults, setShowResults] = useState(false);

  console.log(totalWords);

  const days = Array.from({ length: 10 }, (_, index) => `${index + 1}`);

  const dayClickFetchData = async (day) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/${language}/${day}`
      );
      const data = await res.json();

      if (Array.isArray(data)) {
        const text = data.join(" ");
        setRandomText(text);
        setTotalWords(text.split(" ").length);
        resetTimer();
      } else {
        console.error("Unexpected data format", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const randomClickFetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/getrandompara?isType=${language}`
      );
      const data = await res.json();

      setRandomText(data[0].para);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateResults = () => {
    const totalTypedWords = inputText.trim().split(" ").length;
    const accuracyValue = (
      (correctKeysPressed / totalKeysPressed) *
      100
    ).toFixed(2);
    const wpmValue = (totalTypedWords / selectedMinutes).toFixed(2);

    setAccuracy(accuracyValue);
    setWpm(wpmValue);
  };

  // Timer logic
  useEffect(() => {
    if (timer > 0 && isStarted && !isPaused) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timer === 0 && isStarted) {
      setIsStarted(false);
      setShowResults(true);
    }
  }, [timer, isStarted, isPaused]);

  const handleChange = (e) => {
    calculateResults();
    if (!isStarted || isPaused) return;
    const value = e.target.value;
    setInputText(value);

    const randomChars = randomText.split("");
    const inputChars = value.split("");

    let correctCount = 0;
    inputChars.forEach((char, index) => {
      if (index < randomChars.length && char === randomChars[index]) {
        correctCount++;
      }
    });

    setCorrectKeysPressed(correctCount);
    setTotalKeysPressed(inputChars.length);

    const visibleLines = Math.floor(inputChars.length / 50);
    setScrollIndex(visibleLines);
  };

  const getTextColor = (char, index) => {
    if (index >= inputText.length) return "black";
    return char === inputText[index] ? "green" : "red";
  };

  const startTimer = () => {
    if (isPaused) {
      setIsPaused(false);
    } else {
      setTimer(selectedMinutes * 60);
      setIsStarted(true);
      setShowResults(false); // Hide results when the test starts
      setInputText("");
      setCorrectKeysPressed(0);
      setTotalKeysPressed(0);
      setScrollIndex(0);
    }
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resetTimer = () => {
    setIsStarted(false);
    setTimer(0);
    setInputText("");
    setCorrectKeysPressed(0);
    setTotalKeysPressed(0);
    setScrollIndex(0);
    setShowResults(false); // Hide results when resetting
  };

  const timeData = [0.2, 1, 2, 3, 4, 5, 10, 15, 20, 30];
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <div className="typing-page-layout">
        <div className="typing-sidebar">
          {days.map((day, index) => (
            <button
              key={index}
              className="day-button"
              onClick={() => dayClickFetchData(day)}
              disabled={isStarted}
            >
              Lession {day}
            </button>
          ))}
          <button
            className="day-button"
            onClick={randomClickFetchData}
            disabled={isStarted}
          >
            Random Paragraph
          </button>
        </div>

        <div className="typing-page-body">
          <div className="typing-language-bar">
            <button
              className="typing-login-button"
              onClick={() => navigate("/signup")}
            >
              Login
            </button>
            <div>
              <input
                type="radio"
                id="english"
                name="language"
                value="english"
                checked={language === "english"}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={isStarted}
              />
              <label htmlFor="english">English</label>

              <input
                type="radio"
                id="hindi"
                name="language"
                value="hindi"
                checked={language === "hindi"}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={isStarted}
                style={{ marginLeft: "10px" }}
              />
              <label htmlFor="hindi">Hindi</label>
            </div>
            <h5 className="typing-nav-heading">Switch Typing Test Language</h5>
          </div>

          <div className="typing-word-box">
            {randomText
              .split("")
              .slice(scrollIndex * 60, (scrollIndex + 6) * 60)
              .map((char, index) => (
                <span
                  key={index}
                  style={{
                    color: getTextColor(char, index + scrollIndex * 50),
                  }}
                >
                  {char}
                </span>
              ))}
          </div>

          <div className="typing-input-type">
            <input
              type="text"
              className="typing-input-type-input"
              value={inputText}
              onChange={handleChange}
              style={{ width: "100%", padding: "10px" }}
              placeholder="Start typing here..."
              disabled={!isStarted || isPaused}
            />
            <div className="typing-timer-box">
              <select
                className="typint-box-select-box"
                value={selectedMinutes}
                onChange={(e) => setSelectedMinutes(Number(e.target.value))}
                disabled={isStarted && !isPaused}
              >
                {timeData.map((e, index) => (
                  <option key={index} value={e}>
                    {e} minute{e > 1 && "s"}
                  </option>
                ))}
              </select>
              <button
                className="typing-box-start-button"
                onClick={startTimer}
                style={{ marginLeft: "10px" }}
              >
                {isPaused ? "Resume" : "Start"}
              </button>
              {isStarted && !isPaused && (
                <button
                  className="typing-box-start-button"
                  onClick={pauseTimer}
                  style={{ marginLeft: "10px" }}
                >
                  Pause
                </button>
              )}
              {isStarted && (
                <button
                  className="typing-box-start-button"
                  onClick={resetTimer}
                  style={{ marginLeft: "10px" }}
                >
                  Reset
                </button>
              )}
            </div>
            <div style={{ marginTop: "10px", fontSize: "20px" }}>
              Timer: {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}
            </div>
          </div>

          {showResults && (
            <div className="typing-results">
              <h3>Results</h3>
              <p>Words per minute (WPM): {wpm}</p>
              <p>Accuracy: {accuracy}%</p>
            </div>
          )}
        </div>
        <StudentQuiz />
      </div>

      <Footer />
    </>
  );
};

export default TypingPage;
