import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Homepage from "../componenets/homepage/Homepage";
import TypingPage from "../componenets/typingpage/TypingPage";
import SignupPage from "../componenets/signuppage/SignupPage";
import Loginpage from "../componenets/Loginpage/Loginpage";
import ContectUs from "../componenets/ContectUsPage/ContectUs";
import Course from "../componenets/Coursepage/Course";
import AboutUs from "../componenets/Aboutpage/AboutUs";
import AllUgCourses from "../componenets/Coursepage/AllUgCourses";
import ViewAllBranches from "../componenets/AllBranchespage/ViewAllBranches";
import ErrorPage from "../componenets/errorPage/ErrorPage";
import SplashScreen from "../componenets/splashScreen/SplashScreen";
import Admin from "../componenets/AdminPage/Admin";
import CreateCourse from "../componenets/AdminPage/CreateCourse/CreateCourse";
import GenerateCertificate from "../componenets/AdminPage/GenerateCertificate/GenerateCertificate";
import Sidebar from "../componenets/sidebar/Sidebar";
import GetAllCertificate from "../componenets/GetAllCertifiate/GetAllCertificate";
import GetStudentPage from "../componenets/GetStudent/GetStudentPage";
import Cookies from "js-cookie";
import CreateTyping from "../componenets/CreateTyping/CreateTyping";
import GetAllTypingData from "../componenets/GetAllTypingData/GetAllTypingData";
import AdvertismentDragDrop from "../componenets/Advertisement/AdvertismentDrag&Drop";
import DeleteAd from "../componenets/DeleteAd/DeleteAd";
import MakeAnAdmin from "../componenets/makeAdmin/MakeAnAdmin";
import { useAuth } from "../redux/hook/useAuth";
import CreateQuiz from "../componenets/createQuiz/CreateQuiz";
import GetAllQuiz from "../componenets/GetAllQuiz/GetAllQuiz";
import CreateNotification from "../componenets/CreateNotification/CreateNotification";

const UnprotectedRoutes = () => {
  const isLogged = !!Cookies.get("myToken");
  console.log(isLogged);
  const { user } = useAuth();
  console.log(user?.user?.isAdmin);

  return (
    <div>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        {!isLogged && <Route path="/signup" element={<SignupPage />} />}
        {!isLogged && <Route path="/login" element={<Loginpage />} />}
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/typing" element={<TypingPage />} />
        <Route path="/contectus" element={<ContectUs />} />
        <Route path="/course" element={<Course />} />
        <Route path="/getstudent" element={<GetStudentPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/ugcourses" element={<AllUgCourses />} />
        <Route path="/allbranches" element={<ViewAllBranches />} />
        <Route path="/createQuiz" element={<CreateQuiz />} />
        <Route path="/getAllQuix" element={<GetAllQuiz />} />
        <Route path="*" element={<ErrorPage />} />
        <Route
          path="/admindashboard"
          element={
            user?.user?.isAdmin === "admin" ? (
              <Admin />
            ) : (
              <Navigate to="/homepage" />
            )
          }
        />
        <Route path="/createcourse" element={<CreateCourse />} />
        <Route path="/generatecertificate" element={<GenerateCertificate />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/getAllCertificate" element={<GetAllCertificate />} />
        <Route path="/createTyping" element={<CreateTyping />} />
        <Route path="/getAlltyping" element={<GetAllTypingData />} />
        <Route path="/deleteAd" element={<DeleteAd />} />
        <Route path="/makeAdmin" element={<MakeAnAdmin />} />
        <Route path="/createNotification" element={<CreateNotification />} />

        {isLogged && (
          <Route path="/signup" element={<Navigate to="/homepage" />} />
        )}
        {isLogged && (
          <Route path="/login" element={<Navigate to="/homepage" />} />
        )}
        <Route path="/advertisement" element={<AdvertismentDragDrop />} />
      </Routes>
    </div>
  );
};

export default UnprotectedRoutes;
