import React from 'react';
import "./ViewAllBranches.css";
import Nav from '../Navpage/Nav';
import Footer from '../footer/Footer';
import hitechlogo from "../../Assets/hitech-img.jpg";
import secondbanner from "../../Assets/secondbranch.png"

const branches = [
  {
    id: 1,
    name: 'Branch 1',
    location: 'BDA 96 C, Maa Hinglaj Sewa Sansthan, Shivaji Nagar Bhopal 462016 Mob:0755-401356',
    description: 'This is Our Main Branch',
    image: hitechlogo
  },
  {
    id: 2,
    name: 'Branch 2',
    location: 'E-8/19, Near Canara Bank, Gulmohar Main Road, Dursanchar Colony, Bhopal, Mob:9685370104',
    description: 'This is the second branch.',
    image: secondbanner
  }
];

const infoCenters = [
  {
    id: 1,
    name: 'Center 1',
    address: 'Obedullaganj',

  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Neelbad',
    contact: '0987654321'
  },
  {
    id: 1,
    name: 'Center 1',
    address: 'Misrod',
    contact: '1234567890'
  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Berasiya',
    contact: '0987654321'
  },
  {
    id: 1,
    name: 'Center 1',
    address: 'Piplani',
    contact: '1234567890'
  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Kolar',
    contact: '0987654321'
  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Karond',
    contact: '0987654321'
  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Saket Nager',
    contact: '0987654321'
  },
  {
    id: 2,
    name: 'Center 2',
    address: 'Ichhawar',
    contact: '0987654321'
  },

  // Add more franchise centers here
];

const ViewAllBranches = () => {
  return (
    <>
      <Nav />
      <div id="AllBranchesMain">
        <div id="allBranches-Imagesection">
          <span className='OurBranchesHeading'>Our Branches</span>
        </div>
        <div id="AllBranchesCards">
          <div className="card-container">
            {branches.map((e) => (
              <div key={e.id} className="card">
                <img src={e.image} alt={e.name} className="card-image" />
                <div className="card-content">
                  <h3>{e.name}</h3>
                  <p>{e.location}</p>
                  <p>{e.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id="information-center">
          <h2>Franchise Information Centers</h2>
          <div className="info-center-container">
            {infoCenters.map((center) => (
              <div key={center.id} className="info-card">
                <h3>{center.name}</h3>
                <p>{center.address}</p>

              </div>
            ))}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ViewAllBranches;
