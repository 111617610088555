// import React from "react";
// import "./CardGrid.css";

// const CardGrid = ({ title, desc, index }) => {
//   return (
//     <>
//       <div key={index} className="card">
//         <h3>{title}</h3>
//         <p>{desc}</p>
//       </div>
//     </>
//   );
// };

// export default CardGrid;


import React from "react";
import "./CardGrid.css";

const CardGrid = ({ img, title, type, duration, eligibility, desc }) => {
  return (
    <div className="card-grid-container">
      <div className="card">
        <img src={img} alt="" />
        <div className="card-content1">
          <h2 className="course-title">{title}</h2>
          <p className="course-type"><strong>Type:</strong> {type}</p>
          <p className="course-duration"><strong>Duration:</strong> {duration}</p>
          <p className="course-eligibility"><strong>Eligibility:</strong> {eligibility}</p>
          <p className="course-desc">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default CardGrid;
