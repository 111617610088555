import React from "react";
import "./Footer.css";
import hitechlogo from "../../Assets/hitechlogo.jpg"
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="footer-bar home-footer">
      <div className="footer-bar-inner">
        <div className="column">
          <div id="logo" className="logo">
            <img className="hitechlogo"src={hitechlogo} alt="logo" />
          </div>
          <div className="column1-content">
            Hitech Group of Institutions
            Empowering students with comprehensive degree courses.
            Your path to success begins here, with expert guidance.
            Join us for a brighter future and endless possibilities.
          </div>
        </div>
        <div className="column">
          <h3 className="column-heading">GET HELP</h3>
          <div className="column-four">

          <Link to="/contectus"><h5>Contact Us</h5></Link>
          <Link to="/aboutus"><h5>About Us</h5></Link>
            <br/>

            <div className="dropdown">
              <h5 className="dropbtn">MCQ</h5>
              <div className="dropdown-content">
                <Link to="https://docs.google.com/forms/d/e/1FAIpQLSeg7CnjWKu8BccDWsT27Jf1LcGxJUG2KkG6CrVvR5kG3VnBew/viewform">Power-Point MCQ</Link>
                <Link to="https://docs.google.com/forms/d/e/1FAIpQLSeg7CnjWKu8BccDWsT27Jf1LcGxJUG2KkG6CrVvR5kG3VnBew/viewform">MS Power-Point MCQ</Link>
              </div>
            </div>

            <div className="dropdown">
              <h5 className="dropbtn">Student Login</h5>
              <div className="dropdown-content">
                <Link to="https://mcrpv.mponline.gov.in/Portal/Services/MAKHANLAAL/STUDENT_MGMT_SYS/Login.aspx">MCU University</Link>
                <Link to="https://www.cvrump.ac.in/admissions/searchCourse">Dr. C.V. RAMAN University</Link>
                <Link to="http://162.144.38.91/~mmyvvdde/main/index.php">MMYVV University</Link>
              </div>
            </div>
            

          </div>
        </div>
        <div className="column">
          <h3 className="column-heading">USEFULL LINKS</h3>
          <div className="column-four">

          <div className="dropdown">
              <h5 className="dropbtn">CPCT</h5>
              <div className="dropdown-content">
                <Link to="https://cdn.digialm.com/EForms/configuredHtml/1172/2575/login.html">CPCT Login</Link>
                <Link to="https://www.cpct.mp.gov.in/per/g01/pub/1172/ASM/WebPortal/1/Hindi/PDF/CPCTSyllabus.pdf">CPCT Syllbus</Link>
                <Link to="https://www.cpct.mp.gov.in/per/g01/pub/1172/ASM/WebPortal/1/Hindi/PDF/CPCTExamClarificationGAD04072018.pdf">CPCT Exam Clarification</Link>
                <Link to="https://www.cpct.mp.gov.in/per/g01/pub/1172/ASM/WebPortal/1/Links.html">CPCT Previous Year Question Paper</Link>
                <Link to="https://www.cpct.mp.gov.in/per/g01/pub/1172/ASM/WebPortal/1/Hindi/PDF/Devnagari-Remington-Layout.jpg">CPCT Keyboard Layout Remington (GAIL)</Link>
                <Link to="https://www.cpct.mp.gov.in/per/g01/pub/1172/ASM/WebPortal/1/Hindi/PDF/UnicodeBook.pdf">CPCT Font Information</Link>
              </div>
            </div>


            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSeQau9rZ436DTnXDCCx9nRMMDrphAv_Lf7V7jreEsww-h9Kcg/viewform?usp=send_form"><h5>Admisson Form</h5></Link>
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdf3BbuCTHA3iueLl2LXy1LuyNHHS60fI6PzJC9n9KP2GGjzA/viewform"><h5>Exam Form</h5></Link>

            <div className="dropdown">
              <h5 className="dropbtn">Result</h5>
              <div className="dropdown-content">
                <Link to="http://103.12.1.55:81/mkhu_jun_2021_results_0621/index.php">MAKHANLAL UNIVERSITY</Link>
                <Link to="https://www.aisectonline.com/StudentRes/StudentResultSearchPartialView2">AISECT / RNTU / CVRAMAN</Link>
                <Link to="https://mmyvvdde.com/">MMYVV</Link>
              </div>
            </div>

            <div className="dropdown">
              <h5 className="dropbtn">Syllbus</h5>
              <div className="dropdown-content">
                <Link to="https://www.mcu.ac.in/download/DCA_PGDCA_SYLLABUS_FROM_JULY_2018/DCA_Syllabus_july_2018.pdf">DCA Syllbus-MCU</Link>
                <Link to="https://www.mcu.ac.in/download/DCA_PGDCA_SYLLABUS_FROM_JULY_2018/PGDCA_Syllabus_july_2018.pdf">PGDCA Syllbus-MCU</Link>
                <Link to="/exam-guidelines">BCA Syllbus</Link>
              </div>
            </div>

            




          </div>
        </div>
        <div className="column">
          <h3 className="column-heading">CONTACT US</h3>
          <div className="column-four">
            <h5>
            BDA 96 C, Maa Hinglaj Sewa Sansthan, Shivaji Nagar
            Bhopal (MP) 462016
            </h5>
            <h5>Telephone: +91 0755 4013563</h5>
            <h5>Phone: +91 9752398636 // 9685370104</h5>
            <h5>Mail: hitechgroupbhopal@gmail.com</h5>
            <h5>Operating Hours: Monday - Saturday: 8:00 AM - 8:00 PM
              Sunday: Closed</h5>
          </div>
          <div className="social-media-box">
           <Link to="https://www.facebook.com/hitechgroupofinstitutions" target="main"> <i class="ri-facebook-fill"></i></Link>
           <Link to="https://x.com/AnilMal05363521" target="main"> <i class="ri-twitter-x-line"></i></Link>
            <Link to="https://www.instagram.com/anilmalviyajournalist/" target="main"><i class="ri-instagram-fill"></i></Link>
            <Link to="https://www.youtube.com/@hitechgroupofinstitutions8485" target="main"><i class="ri-youtube-fill"></i></Link>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <h5>Copyright © 2024 HITECH GROUP OF INSTITUTIONS | Akshu & Waseem </h5>
      </div>
    </div>
  );
};

export default Footer;
