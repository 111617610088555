import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './Homepagecarousel.css'


const ImageCarousel = () => {

  const [crausal, setCrausal] = useState([]);


  useEffect(() => {

    const fetchCrucal = async () => {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ad/getAd`);
      const data = await res.json();
      console.log(data);
      setCrausal(data);
    }
    fetchCrucal();

  }, [])



  return (
    <Carousel>
      {
        crausal.map((e) => {
          return <Carousel.Item>
            <img
              className="d-block w-100"
              src={e.imgpath}
              alt="First slide"
              style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
            />
          </Carousel.Item>

        })
      }

    </Carousel>
  );
}

export default ImageCarousel;
