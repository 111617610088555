import React, { useState } from "react";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import HumburgerMenu from "../../icons/HumburgerMenu";
import CloseIcon from "../../icons/CloseIcon";
import Sidebar from "../sidebar/Sidebar";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { delteUser } from "../../redux/userInfo/userInformation";
import hitechlogo from "../../Assets/hitechlogo.jpg";
import { logout } from "../../redux/userInfo/authSlice";

const Nav = () => {
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // isLoggedIn state from Redux
  const user = useSelector((state) => state.auth.user); // user data from Redux

  const drawerHandler = () => {
    setDrawer(!drawer);
  };

  const signoutHandler = () => {
    Cookies.remove("myToken");
    dispatch(logout());
    dispatch(delteUser());
    navigate("/login");
  };

  return (
    <>
      {drawer === false ? (
        <Sidebar setIsDrawer={drawerHandler} isDrawer={"sidebar-page"} />
      ) : (
        <Sidebar setIsDrawer={drawerHandler} isDrawer={"sidebar-page1"} />
      )}
      <div id="navbar">
        {drawer === false ? (
          <HumburgerMenu classNamee="humburger-menu" onclick={drawerHandler} />
        ) : (
          <CloseIcon classNamee="close-menu" onclick={drawerHandler} />
        )}
        <div id="logo">
          <Link className="logos" to="/homepage">
            <img src={hitechlogo} alt="logo" />
          </Link>
        </div>

        <div id="nav-manu" className="signup signin">
          <Link className="nav-manus" to="/homepage">
            Home
          </Link>
          <Link className="nav-manus" to="/course">
            Courses
          </Link>
          <Link className="nav-manus" to="/contectus">
            Contact us
          </Link>
          <Link className="nav-manus" to="/aboutus">
            About us
          </Link>
          <Link className="nav-manus" to="/getstudent">
            Get Student
          </Link>
          <Link className="nav-manus" to="/typing">
            Start Typing
          </Link>
          <Link className="nav-manus">News-Web</Link>
        </div>
        {user?.user?.isAdmin === "admin" ? (
          <Link className="nav-manus" to="/admindashboard">
            Admin
          </Link>
        ) : (
          <></>
        )}

        <div id="navLogRegis">
          {isLoggedIn && user ? ( // If user is logged in and user info is available
            <>
              <div className="user-profile">
                <p className="user-profile-name"> {user.user.username} </p>
                <div className="user-profile-pic">
                  <img
                    src="https://static-00.iconduck.com/assets.00/profile-default-icon-2048x2045-u3j7s5nj.png"
                    alt="Profile"
                  />
                </div>
              </div>

              <div className="user-profile-pic1" onClick={signoutHandler}>
                <i className="ri-logout-box-r-line"></i>
              </div>
            </>
          ) : (
            <div id="right-nav-manus">
              {/* If user is not logged in, show Login and Signup */}

              <Link className="nav-manus" to="/login">
                Login
              </Link>
              <Link className="nav-manus" to="/signup">
                Register
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Nav;
